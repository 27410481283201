import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import * as firebase  from 'firebase';
import AuthLayout from "layouts/Auth.jsx";
import RtlLayout from "layouts/RTL.jsx";
import AdminLayout from "layouts/Admin.jsx";
// import QuizQuestionsBuilder from "./views/Forms/Ques";
import "assets/scss/material-dashboard-pro-react.scss?v=1.5.0";
import SurveyView from "./layouts/SurveyView";
import SurveyCreator from './views/Forms/SurveyCreator'

const hist = createBrowserHistory();
/*var config = {
  apiKey: "AIzaSyCex2ZsnhPN2pB-dQ2NTyBzrukG1Tjd4TU",
  authDomain: "wam-ath-project.firebaseapp.com",
  databaseURL: "https://wam-ath-project-default-rtdb.firebaseio.com",
  projectId: "wam-ath-project",
  storageBucket: "wam-ath-project.appspot.com",
  messagingSenderId: "25108625533"
};*/

// Initialize Firebase
var config = {
  apiKey: "AIzaSyAHRkH7nZAHUwYLkVgg1xFyMARmJeHTRwE",
  authDomain: "we-are-martinsville-8433f.firebaseapp.com",
  databaseURL: "https://we-are-martinsville-8433f.firebaseio.com",
  projectId: "we-are-martinsville-8433f",
  storageBucket: "we-are-martinsville-8433f.appspot.com",
  messagingSenderId: "313526286954"
};
firebase.initializeApp(config);

export const secondaryFirebase = firebase.initializeApp({ ...config }, "Secondary");

ReactDOM.render(
  <Router history={hist}>
    <Switch>
      <Route path="/auth" component={AuthLayout} />
      <Route path="/admin" component={AdminLayout} />
      <Route path="/survey/form/:id" component={SurveyCreator} />
      <Route path="/survey/:id" component={SurveyView} />
      <Redirect from="/" to="/auth/login-page" />
    </Switch>
  </Router>,
  document.getElementById("root")
);
